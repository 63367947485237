<template>
        <MyMap />
</template>
<script>
import MyMap   from "@/components/common/Map";
export default {
    components: {MyMap},
    created(){
        // console.warn('-----------');
    }
}
</script>